/* global $ */
import "../../css/app/components/home.scss";
class Home
{
    constructor () 
    {
        this.initLogoScroll();
        $(window).scroll(() => {
            this.initLogoScroll();
        });
        $(window).resize(() => {
            this.initLogoScroll();
        });
        console.log('Home initialised');
    }

    initLogoScroll() {
        let scrollTop = $(window).scrollTop();
        let scrollHeight = $(window).innerHeight() - 48 - 100;
        $("#scrollLogo").css('margin-left', (scrollHeight - scrollTop) / scrollHeight * 120);
        if ($(window).innerWidth() < 1200) {
            $("#scrollLogo img").css('height', 36 + ((scrollHeight - scrollTop) / scrollHeight * 80));
        } else {
            $("#scrollLogo img").css('height', 36 + ((scrollHeight - scrollTop) / scrollHeight * 120));
        }
        if (scrollTop >= scrollHeight) {
            $("#header").addClass("scrolled");
        } else {
            $("#header").removeClass("scrolled");
        }
    }
}

new Home;